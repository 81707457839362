<template>
	<b-modal
		v-model="isOpen"
		id="askPassword"
		ref="modal-ask-password"
		header-class="header-class-modal-doc-package"
		:title="rendTitle"
		:ok-title="FormMSG(230, 'Ok')"
		:cancel-title="FormMSG(285, 'Cancel')"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
		ok-variant="primary"
		:ok-disabled="loading || passwordInput === '' || !useDigElecSign"
		:cancel-disabled="loading"
		@show="resetModal"
		@hidden="hiddenModal"
		@ok.prevent="handleOk"
		cancel-variant="light"
		button-size="sm"
	>
		<b-overlay :show="loading" opacity="0.85">
			<div class="container-modal px-2">
				<form ref="form" :style="`margin-top: ${$screen.width < 355 ? '25px' : '5px'}`" @submit.stop.prevent="handleSubmit">
					<div v-if="!justVerifyPassword">
						<a href="#" v-bind:class="downloadESA" @click="downloadESA" style="font-size: 1rem">
							{{ FormMSG(295, 'Click here to read Electronic Signature Agreement') }}
						</a>
						<div class="mt-3">
							<b-form-checkbox id="agreeES" v-model="useDigElecSign">
								{{ FormMSG(106, 'I agree to use electronic records and signatures') }}
							</b-form-checkbox>
						</div>
					</div>
					<b-form-group
						:invalid-feedback="`${
							passwordInput === '' || !useDigElecSign ? FormMSG(296, 'You should enter your password and accept the terms.') : ''
						}`"
						:state="passwordState"
						:label="FormMSG(101, 'Password:')"
						class="mt-3"
					>
						<b-input-group>
							<b-form-input
								id="oldPassword"
								v-model="passwordInput"
								:type="showPassword ? 'text' : 'password'"
								:state="passwordState"
								required
								:placeholder="FormMSG(102, 'Enter your password.')"
							/>
							<b-input-group-append>
								<button class="btn-show-password" type="button" @click="showPassword = !showPassword">
									<Eye v-if="!showPassword" color="#9c9d9e" :size="16" />
									<EyeOff v-if="showPassword" color="#9c9d9e" :size="16" />
								</button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</form>
			</div>
			<template #overlay>
				<div class="text-center">
					<loading :active.sync="loading" loader="bars" color="#00A09C" :width="64" />
					<p id="cancel-label" class="loading-text">
						{{ FormMSG(297, 'Please wait...') }}
					</p>
				</div>
			</template>
		</b-overlay>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { verifyPassword } from '@/cruds/users.crud';
import { signDocument } from '@/cruds/document.crud';
import globalMixin from '@/mixins/global.mixin';
import { Eye, EyeOff } from 'lucide-vue';
import Loading from 'vue-loading-overlay';

export default {
	name: 'ModalForSignDocument',
	mixins: [languageMessages, globalMixin],
	props: {
		open: { type: Boolean, default: false, required: false },
		document: { type: Object, default: {}, required: false },
		useRequesterId: { type: Boolean, default: false, required: false },
		requesterId: { type: Number, default: 0, required: false },
		userId: { type: Number, default: 0, required: false },
		title: { type: String, required: false, default: '' },
		justVerifyPassword: { type: Boolean, required: false, default: false }
	},
	components: {
		Eye,
		EyeOff,
		Loading
	},
	data() {
		return {
			passwordInput: '',
			passwordState: null,
			useDigElecSign: false,
			loading: false,
			showPassword: false
		};
	},
	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		rendTitle() {
			if (this.title === '') {
				return this.FormMSG(125, 'Enter your password to sign the document');
			}

			return this.title;
		}
	},
	methods: {
		hiddenModal() {
			this.resetModal();
			this.emitCloseEvent();
		},
		emitCloseEvent() {
			this.$emit('modal-for-sign-document:close');
		},
		async handleOk() {
			this.loading = true;
			await this.handleSubmit();
			this.loading = false;
		},
		async handleSubmit() {
			// Exit when the form isn't valid
			if (!this.checkFormValidity()) {
				// console.log('does not close')
				return;
			}
			await this.verifyPassword();
		},
		async verifyPassword() {
			const passwordVerified = await verifyPassword(store.state.myProfile.email, this.passwordInput);

			if (passwordVerified === 'OK') {
				if (!this.justVerifyPassword) {
					const userId = this.userId;
					const docId = parseInt(this.document.id);
					const requesterId = this.requesterId;
					await signDocument({
						docId,
						userId,
						contractId: this.document.deliveryContractId,
						requesterId,
						docValidationLevel: this.document.docValidationLevel ? this.document.docValidationLevel : null
					});
					this.$emit('modal-for-sign-document:document-signed', {
						docId,
						documentDeliveryId: this.document.documentDeliveryId ? this.document.documentDeliveryId : null
					});
					this.emitCloseEvent();
					this.createToastForMobile(this.FormMSG(251, 'Success'), this.FormMSG(252, 'Doc signed successfully'));
				} else {
					this.$emit('modal-for-sign-document:password-ok');

					this.emitCloseEvent();
				}
			} else {
				var str = this.FormMSG(235, 'You entered a wrong password');
				var strTitle = this.FormMSG(232, 'Error');

				this.createToastForMobile(strTitle, str, '', 'danger');
			}
		},
		checkFormValidity() {
			// console.log('Before checkValidity useDigElecSign:', this.useDigElecSign)
			if (!this.justVerifyPassword) {
				if (this.useDigElecSign == false) {
					alert(this.FormMSG(103, 'You must confirm your agreement to using electronic signature'));
					return false;
				}
			}

			const valid = this.$refs.form.checkValidity();
			this.passwordState = valid;
			// console.log('After checkValidity useDigElecSign:', this.useDigElecSign)
			this.useDigElecSign = true;
			return valid;
		},
		downloadESA() {
			this.warning = '';
			var tmp = store.userID();
			var urlStr = process.env.VUE_APP_GQL + '/ElectronicSignatureAgreement/' + tmp;
			//console.log("viewElectronicSignAgreement url:", urlStr);
			window.open(urlStr); // in new window
			return;
		},
		resetModal() {
			this.passwordState = null;
			this.passwordInput = '';
			this.useDigElecSign = false;
		}
	}
};
</script>
<style lang="scss">
.loading-text {
	color: #06263e;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
}
</style>
